import { Button, ChatNamespaceSelector } from 'components/lib'
import { useWindowSize } from 'hooks'
import { Settings } from 'icons'
import { useCallback, useEffect, useState } from 'react'
import { getOS } from 'utils/helper'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import 'tippy.js/dist/tippy.css' // Core styles
import 'tippy.js/dist/svg-arrow.css' // SVG arrow styles
import 'tippy.js/animations/shift-away.css'
import { HINTS } from 'utils'
import styles from './chatSetting.module.scss'

const TaxAiChatSetting = ({ className = '', filters, onSelectionChange, onAutoChange }) => {
  const os = getOS()
  const [settingOpen, setSettingOpen] = useState(false)
  const { isMobile } = useWindowSize()

  const settingTitle = settingOpen ? 'Hide Chat Settings' : 'Chat Settings'
  const settingHint = os === 'macos' ? HINTS.chatSetting.mac : HINTS.chatSetting.nonMac
  const handleHistoryClose = () => {
    const historyButton = document.getElementById('history-toggle')
    if (historyButton.getAttribute('data-toggle') === 'true') historyButton.click()
  }

  const toggleSetting = useCallback(() => {
    if (!settingOpen && isMobile) handleHistoryClose()
    setSettingOpen((prev) => !prev)
  }, [settingOpen, isMobile])

  const handleKeyPress = useCallback(
    (event) => {
      const isModifierKey = os === 'macos' ? event.metaKey : event.ctrlKey

      if (isModifierKey && event.key === '.') {
        event.preventDefault()
        toggleSetting()
      }
    },
    [toggleSetting]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)
    return () => window.removeEventListener('keydown', handleKeyPress)
  }, [handleKeyPress])

  return (
    <>
      <div
        className={`${className} ${styles['chat-setting-panel-c']} ${
          !settingOpen ? styles.open : ''
        } inline z-10 overflow-y-auto sm:left-0 left-10 m-0 absolute p-2 sm:pl-5`}
      >
        <Tippy content={`${settingTitle} (${settingHint})`} arrow={roundArrow} animation="shift-away" inertia={true}>
          <div>
            <Button
              id="chat-settings"
              data-toggle={settingOpen}
              icon={settingOpen && 'log-out'}
              customIcon={
                settingOpen ? undefined : <Settings className={'w-fit h-fit m-auto'} height={22} width={22} />
              }
              color={'dark'}
              size={20}
              className="bg-white rounded-full inline h-10 w-10 shadow mt-1 drop-shadow border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20"
              action={(e) => {
                if (!settingOpen && isMobile) handleHistoryClose()
                setSettingOpen(!settingOpen)
              }}
            />
          </div>
        </Tippy>
      </div>
      <div
        style={settingOpen ? { boxShadow: '1px 0 2px 1px rgba(0, 0, 0, 0.1)' } : {}}
        className={`${className} ${styles['chat-setting-panel']} ${
          settingOpen ? styles.open : ''
        } inline z-10 overflow-y-auto left-0 m-0 absolute p-2 sm:pl-5 bg-white lg:w-[13rem] xl:w-[15rem] 2xl:w-[19rem] w-[19rem] h-full top-0 `}
      >
        <div className={'mb-2'}>
          <div className="flex justify-between items-center mb-5">
            {<div className="flex gap-8 mx-auto sm:mx-0 text-lg">Chat Settings </div>}
            <Tippy
              content={`${settingTitle} (${settingHint})`}
              arrow={roundArrow}
              animation="shift-away"
              inertia={true}
            >
              <div>
                <Button
                  id="chat-settings"
                  data-toggle={settingOpen}
                  icon={'log-out'}
                  color={'dark'}
                  size={20}
                  className="mt-1 bg-white inline !h-10 !w-10 rounded-full drop-shadow [&>*]:w-fit [&>*]:m-auto [&>*]:left-1/2 [&>*]:-translate-x-1/2 [&>*]:-translate-y-1/2 [&>*]:rotate-180 border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20"
                  action={(e) => {
                    if (!settingOpen && isMobile) handleHistoryClose()
                    setSettingOpen(!settingOpen)
                  }}
                />
              </div>
            </Tippy>
          </div>
          {settingOpen && (
            <div className="flex gap-8 mx-auto sm:mx-0">
              <ChatNamespaceSelector
                filters={filters}
                onSelectionChange={onSelectionChange}
                onAutoChange={onAutoChange}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default TaxAiChatSetting

import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '../sheet/sheet'
import { CrossIcon } from 'icons'
import { cn } from 'utils'

export const Drawer = ({
  triggerButton,
  title,
  children,
  open,
  sheetSide,
  onOpenChange,
  className,
  width = '28rem',
}) => {
  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      {triggerButton ? <SheetTrigger asChild>{triggerButton}</SheetTrigger> : null}
      <SheetContent side={sheetSide} className={cn(`bg-white w-[${width}] z-[100]`, className)}>
        <SheetHeader className="border-b p-4 border-gray-200">
          <SheetTitle className="flex w-full justify-between items-center">
            <h3 className="text-primary-dark-blue text-left font-bold text-base">{title}</h3>
            <SheetClose className="text-gray-400 hover:text-primary-dark-blue">
              <span className="sr-only">Close</span>
              <CrossIcon />
            </SheetClose>
          </SheetTitle>
        </SheetHeader>

        <SheetDescription className="z-10 h-[calc(100dvh-2.9rem)] p-4 overflow-auto">{children}</SheetDescription>
      </SheetContent>
    </Sheet>
  )
}

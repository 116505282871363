const Style = {
  switch: 'relative flex items-center gap-2 w-full h-[27.5px] select-none',
  label: 'top-1 normal-case',
  track:
    'relative top-0 left-0 w-[52.5px] h-[27.5px] cursor-pointer overflow-hidden rounded-full ease-in-out duration-300',
  trackOn: 'bluepurple-animation',
  trackOff: 'bg-slate-300',
  handle: `absolute top-1/2 text-white h-[20px] w-[20px] text-sm text-enter font-semibold pointer-events-none 
    bg-white rounded-full -translate-y-1/2 ease-in-out duration-300`,

  handleOff: 'left-1',
  handleOn: 'left-7',
}

export default Style

import { Link, ClassHelper } from 'components/lib'

import LogoWhite from './images/logo-white.svg'
import LogoMarkWhite from './images/logo-mark-white.svg'
import LogoMarkWhiteXmas from './images/logo-mark-white-xmas.svg'

import LogoColor from './images/logo-color.svg'
import LogoMarkColor from './images/logo-mark-color.svg'

import Style from './logo.tailwind.js'

function isInChristmasRange() {
  const now = new Date()
  const year = now.getFullYear()
  const start = new Date(year, 11, 18)
  const end = new Date(year + 1, 0, 4)
  return now >= start && now < end
}

export function Logo(props) {
  const Logo = {
    color: {
      logo: LogoColor,
      mark: LogoMarkColor,
    },
    white: {
      logo: LogoWhite,
      mark: props.christmasMode && isInChristmasRange() ? LogoMarkWhiteXmas : LogoMarkWhite,
    },
  }

  const logoStyle = ClassHelper(Style, props)

  return (
    <Link url="/" className={logoStyle}>
      <img src={Logo[props.color ? 'color' : 'white'][props.mark ? 'mark' : 'logo']} alt="Logo" />
    </Link>
  )
}

import React, { useContext } from 'react'
import { Dialog, DialogContent, DialogOverlay, DialogHeader, DialogTitle, DialogDescription } from './dialog'
import { ViewContext, Form } from 'components/lib'

const LcModal = (props) => {
  const context = useContext(ViewContext)

  const handleCancel = () => {
    context.lcModal.hide(true)
  }

  const handleCallback = (data) => {
    if (props.callback) props.callback(data)
  }

  return (
    <Dialog open={props.show} onOpenChange={(isOpen) => !isOpen && handleCancel()}>
      <DialogOverlay />
      <DialogContent className={props.className ? props.className : 'new-modal-content'}>
        <DialogHeader>
          <DialogTitle>{props.title || 'Default Title'}</DialogTitle>
          <DialogDescription>{props.text}</DialogDescription>
        </DialogHeader>
        {props.form ? (
          <Form
            method={props.method}
            url={props.url}
            inputs={props.form}
            destructive={props.destructive}
            buttonText={props.buttonText || 'Submit'}
            cancel={handleCancel}
            callback={handleCallback}
          />
        ) : (
          props.children
        )}
      </DialogContent>
    </Dialog>
  )
}

export { LcModal }

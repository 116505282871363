import * as DialogPrimitive from '@radix-ui/react-dialog'
import { CrossIcon } from 'icons'
import { forwardRef } from 'react'
import { cn } from 'utils'

const Dialog = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const DialogClose = DialogPrimitive.Close

const DialogOverlay = forwardRef(({ className, ...rest }, ref) => {
  return (
    <DialogPrimitive.Overlay
      ref={ref}
      className={cn(
        'fixed inset-0 z-[100] bg-black/70 backdrop-blur-sm transition-opacity duration-200 data-[state=open]:opacity-100 data-[state=closed]:opacity-0',
        className
      )}
      {...rest}
    />
  )
})
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

const DialogContent = forwardRef(({ className, children, ...rest }, ref) => {
  return (
    <DialogPortal>
      <DialogOverlay />
      <DialogPrimitive.Content
        ref={ref}
        className={cn(
          'fixed left-1/2 top-1/2 z-[101] w-full max-w-md transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 pt-4 rounded-lg shadow-lg transition-transform duration-300 data-[state=open]:scale-100 data-[state=closed]:scale-95 data-[state=open]:opacity-100 data-[state=closed]:opacity-0',
          className
        )}
        {...rest}
      >
        {children}
        <DialogPrimitive.Close className="absolute top-6 right-4 text-gray-500 hover:text-gray-800 focus:outline-none">
          <CrossIcon />
          <span className="sr-only">Close</span>
        </DialogPrimitive.Close>
      </DialogPrimitive.Content>
    </DialogPortal>
  )
})
DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({ className, ...rest }) => {
  return <div className={cn('mb-8 text-center sm:text-left', className)} {...rest} />
}
DialogHeader.displayName = 'DialogHeader'

const DialogFooter = ({ className, ...rest }) => {
  return <div className={cn('flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2', className)} {...rest} />
}
DialogFooter.displayName = 'DialogFooter'

const DialogTitle = forwardRef(({ className, ...rest }, ref) => {
  return (
    <DialogPrimitive.Title ref={ref} className={cn('text-xl font-bold text-gray-900 mb-2 pb-5', className)} {...rest} />
  )
})
DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = forwardRef(({ className, ...rest }, ref) => {
  return <DialogPrimitive.Description ref={ref} className={cn('text-sm text-muted-foreground', className)} {...rest} />
})
DialogDescription.displayName = DialogPrimitive.Description.displayName

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
}

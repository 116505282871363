import Checkbox from 'components/form/newCheckbox'
import React, { useState, useEffect } from 'react'

const FilterOption = ({ option, value, onFilterChange, isFirstLayerOption = false }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [checkboxState, setCheckboxState] = useState({
    checked: false,
    indeterminate: false,
  })
  const isNested = typeof value === 'object'
  const isLeafNode = value.hasOwnProperty('checked')

  useEffect(() => {
    if (isNested) {
      const { allChecked, someChecked } = calculateCheckboxState(value)
      setCheckboxState({
        checked: allChecked,
        indeterminate: !allChecked && someChecked,
      })
    } else {
      setCheckboxState({
        checked: value,
        indeterminate: false,
      })
    }
  }, [value, isNested])

  const calculateCheckboxState = (obj) => {
    let allChecked = true
    let someChecked = false
    const checkNested = (nestedObj) => {
      for (const key in nestedObj) {
        if (typeof nestedObj[key] === 'object') {
          const { allChecked: nestedAllChecked, someChecked: nestedSomeChecked } = calculateCheckboxState(
            nestedObj[key]
          )
          allChecked = allChecked && nestedAllChecked
          someChecked = someChecked || nestedSomeChecked
        } else {
          if (key === 'checked') {
            allChecked = allChecked && nestedObj[key]
            someChecked = someChecked || nestedObj[key]
          }
        }
      }
    }
    checkNested(obj)
    return { allChecked, someChecked }
  }

  const handleCheckboxChange = (isChecked) => {
    if (isNested) {
      const updateNestedValues = (obj) => {
        const updatedObj = {}
        for (const key in obj) {
          if (typeof obj[key] === 'object') {
            updatedObj[key] = updateNestedValues(obj[key])
          } else {
            if (key === 'checked') {
              updatedObj.checked = isChecked
            }
            updatedObj.value = obj.value
          }
        }
        return updatedObj
      }
      onFilterChange([], updateNestedValues(value))
    } else {
      onFilterChange([], isChecked)
    }
  }

  const handleExpand = (state) => {
    setIsCollapsed(state)
  }

  return (
    <div>
      <Checkbox
        className=""
        checkBoxClassName={isFirstLayerOption ? 'mt-5' : ''}
        item={{
          checked: checkboxState.checked,
          indeterminate: checkboxState.indeterminate,
          label: option,
        }}
        onChange={(e) => handleCheckboxChange(e.target.checked)}
        isGroup={isNested && !isLeafNode}
        handleExpand={handleExpand}
      />
      {isNested && isCollapsed && !isLeafNode && (
        <div style={{ paddingLeft: '20px' }} className="animate-expand overflow-hidden">
          {Object.keys(value).map((childOption) => (
            <FilterOption
              key={childOption}
              option={childOption}
              value={value[childOption]}
              onFilterChange={(optionPath, isChecked) => {
                onFilterChange([childOption, ...optionPath], isChecked)
              }}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default FilterOption

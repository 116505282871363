import { Fragment, useEffect, useState } from 'react'
import React from 'react'
import 'tippy.js/dist/tippy.css' // Core styles
import 'tippy.js/dist/svg-arrow.css' // SVG arrow styles
import 'tippy.js/animations/shift-away.css'
import { AuthContext, ChatInputForm, Icon } from 'components/lib'
import { useRef } from 'react'
import Style from './conversationMessage.tailwind.js'
import { useContext } from 'react'
import { practiceAreas } from '@lawcyborg/packages'

export const ConversationMessage = React.memo(
  ({ message, loading, branch, swapBranch, canSend, siblingCount, siblingIndex, id, practiceAreaId }) => {
    const [editing, setEditing] = useState(false)
    const [editMessage, setEditMessage] = useState(message)
    const inputRef = useRef(null)

    const authContext = useContext(AuthContext)

    useEffect(() => {
      setEditMessage(message)
    }, [id])

    useEffect(() => {
      if (!canSend) {
        setEditing(false)
      }
    }, [canSend])

    useEffect(() => {
      if (editing && inputRef.current) {
        inputRef.current.focus()
        const len = inputRef.current.value.length
        inputRef.current.setSelectionRange(len, len)
      }
    }, [editing])

    const renderIcon = (image, size = 24, color = 'grey') => (
      <Icon className={Style.icon} image={image} color={color} size={size} />
    )

    const hasNavOptions = siblingCount > 1 && !editing && canSend

    return (
      <Fragment>
        <div
          className={`message relative overflow-visible whitespace-normal mx-3 px-4 text-left text-black/90 drop-shadow-lg border-x border-t border-black/5 bg-gray-50 self-end rounded-tl-md font-medium text-base pt-3 pb-2 ${
            loading && 'rounded-b-md mb-8'
          }`}
          style={{ overflowWrap: 'anywhere' }}
        >
          {practiceAreaId && !loading && (
            <div
              className="absolute -top-[29px] -right-[1px] rounded-t-md border-x border-t border-black/5 bg-gray-50 text-gray-800 text-sm font-semibold whitespace-nowrap px-4 py-1"
              // style={{ textShadow: '0 1px 2px rgba(0,0,0,0.1)' }}
            >
              {Object.values(practiceAreas)
                .find((pa) => pa.id === practiceAreaId)
                .name.replace(/^NZ\s*/, '')}
            </div>
          )}

          <div className="message__text whitespace-pre-wrap relative">
            <>
              {canSend && (
                <div
                  className={Style.iconWrapper}
                  onClick={() => {
                    setEditing(!editing)
                    if (!editing) {
                      setEditMessage(editMessage)
                    }
                  }}
                >
                  {renderIcon(editing ? 'x' : 'edit', editing ? 24 : 23)}
                </div>
              )}

              {hasNavOptions ? (
                siblingIndex + 1 < siblingCount ? (
                  <div
                    className={Style.iconWrapper}
                    onClick={() => {
                      swapBranch(id, 1)
                    }}
                  >
                    {renderIcon('arrow-right')}
                  </div>
                ) : (
                  // LV Cheeky hack to keep icons aligned
                  <div
                    className="inline top-0 float-right ml-2 hover:text-black/80"
                    style={{ width: '24px', height: '24px' }}
                  ></div>
                )
              ) : null}

              {siblingCount > 1 && (
                <div className={`message__branch ${Style.iconWrapper}`}>
                  {siblingIndex + 1}/{siblingCount}
                </div>
              )}
              {hasNavOptions && siblingIndex + 1 > 1 && (
                <div
                  className={Style.iconWrapper}
                  onClick={() => {
                    swapBranch(id, -1)
                  }}
                >
                  {renderIcon('arrow-left')}
                </div>
              )}
            </>
            {editing ? (
              <div>
                <ChatInputForm
                  forwardedRef={inputRef}
                  inputMessage={editMessage}
                  setInputMessage={(inputMessage) => {
                    setEditMessage(inputMessage)
                  }}
                  handleSubmit={() => {
                    setEditing(false)
                    setEditMessage(editMessage)
                    branch(id, editMessage)
                  }}
                  enableSend={editMessage}
                  inputReadOnly={false}
                  placeholderText={message}
                  onKeyDown={(e) => {
                    if (e.key === 'Escape') {
                      setEditing(false)
                    }
                  }}
                />
              </div>
            ) : (
              <div>{message}</div>
            )}
          </div>
        </div>
      </Fragment>
    )
  }
)

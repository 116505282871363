import React, { createContext, useState } from 'react'

export const SearchInputContext = createContext()

export const SearchInputProvider = ({ children }) => {
  const [searchInputValue, setSearchInputValue] = useState('')
  const [strictSearch, setStrictSearch] = useState(false)

  return (
    <SearchInputContext.Provider value={{ searchInputValue, setSearchInputValue, strictSearch, setStrictSearch }}>
      {children}
    </SearchInputContext.Provider>
  )
}

import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, Drawer, TextInput } from 'components/lib'
import { cn } from 'utils'
import useCallAPI from 'components/hooks/callApi'

const EMPTY_FIELD = { value: '', valid: undefined }
const INITIAL_FORM_DATA = {
  reason: EMPTY_FIELD,
  topics: [
    {
      value: EMPTY_FIELD,
      weighting: EMPTY_FIELD,
    },
  ],
  context: EMPTY_FIELD,
  question: EMPTY_FIELD,
}

const DrawerContent = ({ onSubmit, selectedPracticeArea, concept }) => {
  // State
  const isEdit = Boolean(concept?.id)
  const [formData, setFormData] = useState(() => {
    if (isEdit) {
      return {
        reason: { value: concept.reason_included || '', valid: concept.reason_included ? true : undefined },
        context: { value: concept.context || '', valid: concept.context ? true : undefined },
        topics: concept.topics.map((topic) => ({
          id: topic.id,
          value: { value: topic.topic || '', valid: topic.topic ? true : undefined },
          weighting: { value: topic.weighting?.toString() || '', valid: topic.weighting ? true : undefined },
        })),
      }
    }
    return INITIAL_FORM_DATA
  })

  // API calls
  const [submitConcepts, , loading] = useCallAPI({
    url: '/api/account/concepts',
    method: 'POST',
  })
  const [editConcept, , loadingEdit] = useCallAPI({
    url: `/api/account/concepts/${concept?.id}`,
    method: 'PUT',
  })
  const [testConcept, , loadingTest] = useCallAPI({
    url: '/api/account/test-concept',
    method: 'POST',
  })

  // Handler functions
  const handleChange = (field, value, valid) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: { value, valid },
    }))
  }

  const handleTopicChange = (topicIndex, field, value, valid) => {
    setFormData((prevFormData) => {
      const updatedTopics = prevFormData.topics.map((topic, index) =>
        index === topicIndex ? { ...topic, [field]: { value: String(value), valid } } : topic
      )
      return {
        ...prevFormData,
        topics: updatedTopics,
      }
    })
  }

  const addTopicField = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      topics: [...prevFormData.topics, { value: '', weighting: { value: '', valid: undefined } }],
    }))
  }

  const removeTopicField = (topicIndex) => {
    setFormData((prevFormData) => {
      const updatedTopics = prevFormData.topics.filter((_, index) => index !== topicIndex)
      return {
        ...prevFormData,
        topics: updatedTopics,
      }
    })
  }

  const isFieldValid = (field) => field?.valid === true

  const isTopicValid = useMemo(
    () => formData.topics.every((topic) => isFieldValid(topic.value) && isFieldValid(topic.weighting)),
    [formData]
  )

  const isValid = useMemo(() => {
    if (!isFieldValid(formData.reason) || !isFieldValid(formData.context)) {
      return false
    }
    return isTopicValid
  }, [formData])
  // Submit function
  const handleSubmit = () => {
    if (!isValid) return
    const conceptTopicMap = new Map(concept?.topics?.map((t) => [t.id, t]))
    const formDataTopicIds = new Set(formData?.topics?.map((topic) => topic.id))

    const params = {
      concept_data: formData?.topics?.map((topic) => {
        const existingTopic = conceptTopicMap?.get(topic.id)

        return {
          topic: topic.value?.value,
          weighting: topic.weighting.value,
          ...(isEdit && {
            id: existingTopic?.id ?? null,
            is_edited:
              existingTopic &&
              !(
                existingTopic.topic === topic.value?.value && existingTopic.weighting === Number(topic.weighting.value)
              ),
          }),
        }
      }),
      ...(isEdit && {
        deleted_topics: concept.topics.filter((topic) => !formDataTopicIds.has(topic.id))?.map((topic) => topic.id),
      }),
      practice_area_id: selectedPracticeArea,
      reason_included: formData.reason.value,
      context: formData.context.value,
    }

    if (isEdit) {
      editConcept({
        requestData: params,
        onSuccess: onSubmit,
      })
    } else {
      submitConcepts({
        requestData: params,
        onSuccess: onSubmit,
      })
    }
  }
  const handleTestConcept = () => {
    const params = {
      concept_data: formData.topics.map((topic) => ({
        topic: topic.value?.value,
        weighting: topic.weighting.value,
      })),
      inputQuestion: formData.question.value,
    }
    testConcept({
      requestData: params,
      onSuccess: (res) => {
        const updatedTopics = formData.topics.map((topic, index) => {
          const responseTopic = res.data[index]
          return {
            ...topic,
            included: responseTopic?.included,
            breakevenWeight: responseTopic?.breakeven_weight,
          }
        })

        setFormData((prevFormData) => ({
          ...prevFormData,
          topics: updatedTopics,
        }))
      },
    })
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="mb-4">
        <div className="mb-6">
          <TextInput
            label="Reason"
            id="reason"
            name="reason"
            value={formData.reason.value}
            valid={formData.reason.valid}
            required
            autoFocus
            placeholder="Enter reason"
            onChange={(name, value, valid) => handleChange('reason', value, valid)}
          />
        </div>
        <div className="mb-2">
          {formData.topics.map((topic, topicIndex) => (
            <div key={topicIndex} className="flex gap-2 mb-2 items-center">
              <TextInput
                label="Topic"
                value={topic.value?.value}
                placeholder="Enter topic"
                required
                valid={topic?.value?.valid}
                onChange={(name, value, valid) => handleTopicChange(topicIndex, 'value', value, valid)}
              />
              <TextInput
                label="Weighting"
                value={topic.weighting?.value || ''}
                valid={topic.weighting?.valid}
                placeholder="Enter weighting"
                required
                onChange={(name, value, valid) => handleTopicChange(topicIndex, 'weighting', value, valid)}
              />
              {typeof topic.included !== 'undefined' && (
                <TextInput label="Included" value={topic.included === 0 ? 'No' : 'Yes'} disabled />
              )}
              {topic.breakevenWeight && <TextInput label="Breakeven Weight" value={topic.breakevenWeight} disabled />}
              <Button
                icon="trash"
                disabled={formData.topics?.length <= 1}
                iconSize={16}
                iconColor={formData.topics?.length > 1 ? '#EF4444' : '#ef444468'}
                action={() => removeTopicField(topicIndex)}
              />
            </div>
          ))}
          <Button
            textOnly
            icon="plus"
            text="Add Topic"
            className={cn(' font-semibold mb-4 !text-brand-500')}
            action={addTopicField}
            small
          />
        </div>
        <div className="mb-2">
          <TextInput
            label="Context"
            id="context"
            name="context"
            type="textarea"
            errorMessage={formData.context.value.length > 3000 ? `Please enter a value less than 3000 characters.` : ''}
            maxLength={3000}
            value={formData.context.value}
            valid={formData.context.valid}
            required
            placeholder="Enter context"
            onChange={(name, value, valid) => handleChange('context', value, valid)}
          />
        </div>
        <div className="">
          <h2 className="text-[1rem] font-semibold text-brand-500 my-6">Test Core Concept</h2>
          <div className="mb-6">
            <TextInput
              label="Question"
              id="question"
              name="question"
              type="textarea"
              value={formData.question?.value}
              placeholder="Enter question"
              onChange={(_, value) => handleChange('question', value, value.length > 0)}
            />
            <Button
              text="Test"
              loading={loadingTest}
              disabled={!(isTopicValid && formData.question?.valid)}
              className={cn(' font-semibold mt-4 !text-brand-500')}
              action={handleTestConcept}
            />
          </div>
        </div>
      </div>
      <Button
        text="Submit"
        loading={loading || loadingEdit}
        disabled={!isValid || loadingTest}
        type="submit"
        action={handleSubmit}
        color="brand3"
        className="mt-4"
      />
    </form>
  )
}

const AddEditConcepts = ({ onClose, selectedPracticeArea }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const isPurchaseProductOpen = searchParams.get('manageConcept') === 'true'
  const concept = JSON.parse(searchParams.get('concept'))
  const isEdit = Boolean(concept?.id)

  const handleOpenManageConcept = (isOpen) => {
    if (searchParams.get('manageConcept') === isOpen) return
    const params = new URLSearchParams(window.location.search)
    if (isOpen) {
      params.set('manageConcept', isOpen)
    } else {
      params.delete('manageConcept')
      params.delete('conceptId')
      params.delete('concept')
      onClose()
    }
    setSearchParams(params)
  }

  const triggerButton = <Button small text="Add Concepts" action={() => handleOpenManageConcept(true)} color="brand3" />

  return (
    <Drawer
      open={isPurchaseProductOpen}
      onOpenChange={handleOpenManageConcept}
      triggerButton={triggerButton}
      title={`${isEdit ? 'Edit' : 'Add'} Concepts`}
      width="36rem"
    >
      <DrawerContent
        selectedPracticeArea={selectedPracticeArea}
        concept={concept}
        onSubmit={() => {
          onClose()
          handleOpenManageConcept(false)
        }}
      />
    </Drawer>
  )
}

export default AddEditConcepts

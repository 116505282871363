import { useState, useRef, useMemo, useContext } from 'react'
import {
  ChatInputForm,
  ConversationDisplay,
  ChatDisclaimer,
  useConversation,
  useConversationAutoScroll,
  AuthContext,
} from 'components/lib'

import { createDiscliamer, disclaimers, PaDisclaimerTemplate } from './definitions'
import ChatHistory from 'components/ai/chatHistory'
import { chatTypeIds, practiceAreaIds } from '@lawcyborg/packages'
import { SettingsContext } from 'app/settings'
import { getRandomElements } from 'utils'

export function AIAuto() {
  const auth = useContext(AuthContext)
  const settingsContext = useContext(SettingsContext)
  const chatLogRef = useRef()
  const [autoScroll, setAutoScroll] = useState(true)
  const {
    conversations,
    exchanges,
    inputMessage,
    setInputMessage,
    handleSubmit,
    errorMessage,
    loading,
    canSend,
    loadingConversation,
    branch,
    swapBranch,
    loadConversation,
    conversationId,
    stopStreaming,
    loadConversations,
    historyCached,
  } = useConversation({ practiceArea: chatTypeIds.AUTO, chatType: 'aiTaxNew', usesChatHistory: true })

  useConversationAutoScroll(chatLogRef, autoScroll, setAutoScroll, exchanges)

  const usedDisclaimers = useMemo(() => {
    const availableLicensesSet = new Set(auth?.user?.userLicenses?.map((license) => license.module_id))
    const paLicenseIds = Array.from(availableLicensesSet).filter((licence) =>
      Object.values(practiceAreaIds).includes(licence)
    )

    const shouldHideAiGeneral = settingsContext?.settings?.disable_tool_ai_general || false
    let availableChatIds = paLicenseIds
    if (!shouldHideAiGeneral) availableChatIds.push(chatTypeIds.GENERAL)

    const allDisclaimers = Object.entries(disclaimers.nz).filter(([key]) => availableChatIds.includes(parseInt(key)))
    const allExampleContent = allDisclaimers.flatMap(([paId, value]) => {
      const content = value.find((item) => item.title === 'Examples').content
      if (paId === chatTypeIds.GENERAL) return getRandomElements(content, 3)
      return content
    })

    const disclaimer = createDiscliamer(PaDisclaimerTemplate, [{ title: 'Examples', content: allExampleContent }])
    return disclaimer
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const displayErrorMessage = errorMessage && <h2 className="text-red-600 text-center">error {errorMessage}</h2>

  const MAX_CHAT_LENGTH = 19
  return (
    <div className="flex flex-col p-[1.5rem] h-full w-full no-scrollbar relative overflow-x-hidden">
      <ChatHistory
        loadConversation={loadConversation}
        currentConversationId={conversationId}
        conversations={conversations}
        bgHexColor={'#f3f8fa'}
        onOpen={loadConversations}
        loading={!historyCached}
        enableRefresh={exchanges.length}
        isStreaming={!loading && !canSend}
      />

      <div ref={chatLogRef} className="flex1 self-center overflow-y-auto w-[100%] no-scrollbar" id="chat">
        {!(loadingConversation || exchanges.length) && (
          <ChatDisclaimer setInputMessage={setInputMessage} handleSubmit={handleSubmit} disclaimers={usedDisclaimers} />
        )}

        {displayErrorMessage}

        <ConversationDisplay
          exchanges={exchanges}
          loading={loading}
          loadingConversation={loadingConversation}
          branch={branch}
          swapBranch={swapBranch}
          canSend={canSend}
          showPracticeArea={true}
        />
      </div>

      <div className="lg:w-[67%] md:w-[90%] self-center relative webfill flex items-center justify-center ">
        <ChatInputForm
          stopStreaming={stopStreaming}
          inputMessage={inputMessage}
          autoFocus
          setInputMessage={setInputMessage}
          handleSubmit={handleSubmit}
          enableSend={canSend}
          inputReadOnly={exchanges.length >= MAX_CHAT_LENGTH}
          placeholderText={
            exchanges.length >= MAX_CHAT_LENGTH
              ? 'A maximum of 10 questions can be asked in this chat. Please refresh to start a new conversation.'
              : 'Ask a question...'
          }
        />
      </div>
    </div>
  )
}

import React from 'react'
import { Card, Table, useAPI } from 'components/lib'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import { COMPANY_ADDRESS } from 'utils'
import { brandLogo } from './logo'

const generateExactInvoicePDF = (invoiceData) => {
  const pdf = new jsPDF('p', 'mm', 'a4')

  const {
    due_date,
    date,
    number: invoiceNumber,
    customer_email,
    plans,
    total,
    coming_month_subtotal,
    proration_amount,
    sub_total,
    gst_amount,
  } = invoiceData

  let margin = 15
  const pageWidth = pdf.internal.pageSize.getWidth()
  let yOffset = margin
  pdf.setDrawColor(0) // Black color
  pdf.setLineWidth(0.2) // Border thickness

  // Header
  pdf.setFont('helvetica', 'bold')
  pdf.setFontSize(16)
  pdf.text('Law Cyborg Limited', margin, yOffset)
  // pdf.text('Invoice', margin + 150, yOffset)
  pdf.addImage(brandLogo, 'PNG', pageWidth - 50, yOffset - 5, 40, 7)
  yOffset += 8

  pdf.setFont('helvetica', 'normal')
  pdf.setFontSize(10)
  const addressLines = COMPANY_ADDRESS.split('\n')
  addressLines.forEach((line) => {
    pdf.text(line, margin, yOffset)
    yOffset += 5
  })
  margin += 3
  yOffset += 8

  // Invoice Metadata
  pdf.setFont('helvetica', 'bold')
  pdf.text(`Invoice Number`, margin, yOffset)
  pdf.text(invoiceData.number || 'N/A', margin + 50, yOffset)
  yOffset += 5

  pdf.setFont('helvetica', 'normal')
  pdf.text(`Date of Issue`, margin, yOffset)
  pdf.text(new Date(date).toLocaleDateString(), margin + 50, yOffset)

  if (due_date) {
    yOffset += 5
    pdf.text(`Date Due`, margin, yOffset)
    pdf.text(new Date(due_date * 1000).toLocaleDateString(), margin + 50, yOffset)
  } // Convert Unix timestamp
  yOffset += 10

  // Billing Information
  pdf.setFont('helvetica', 'bold')
  pdf.text('Law Cyborg Limited', margin, yOffset)
  pdf.text('Bill to', margin + 120, yOffset)
  yOffset += 5

  pdf.setFont('helvetica', 'normal')
  pdf.text('support@lawcyborg.com', margin, yOffset)
  pdf.text(customer_email || 'N/A', margin + 120, yOffset)
  yOffset += 10

  // Table Header
  pdf.setFontSize(12)
  pdf.setFont('helvetica', 'bold')
  pdf.text('Current plan quantities', margin, yOffset)
  yOffset += 5

  pdf.setFontSize(10)
  pdf.setFont('helvetica', 'bold')
  pdf.text('Description', margin, yOffset)
  pdf.text('Qty', margin + 100, yOffset)
  pdf.text('Unit Price', margin + 120, yOffset)
  pdf.text('Amount', margin + 150, yOffset)
  yOffset += 8
  pdf.line(margin, yOffset - 5, pageWidth - margin, yOffset - 5) // Horizontal line

  pdf.setFont('helvetica', 'normal')
  plans.forEach((plan) => {
    pdf.text(plan.description, margin, yOffset)
    pdf.text(`${plan.quantity}`, margin + 100, yOffset)
    pdf.text(plan.unit_amount_excluding_tax, margin + 120, yOffset)
    pdf.text(plan.amount, margin + 150, yOffset)
    yOffset += 6
  })
  pdf.line(margin, yOffset - 3, pageWidth - margin, yOffset - 3)

  yOffset += 2

  // Totals Section
  pdf.setFont('helvetica', 'normal')
  pdf.text(`Coming month subtotal:`, margin + 30, yOffset)
  pdf.text(`${coming_month_subtotal}`, margin + 150, yOffset)
  yOffset += 6

  pdf.text(`Previous month proration adjustment* :`, margin + 30, yOffset)
  pdf.text(`${proration_amount}`, margin + 150, yOffset)
  yOffset += 6

  pdf.text(`Subtotal:`, margin + 30, yOffset)
  pdf.text(`${sub_total}`, margin + 150, yOffset)
  yOffset += 6

  pdf.text(`GST (15%):`, margin + 30, yOffset)
  pdf.text(`${gst_amount}`, margin + 150, yOffset)
  pdf.line(margin + 30, yOffset + 2, pageWidth - margin, yOffset + 2) // Horizontal line
  yOffset += 6

  pdf.setFont('helvetica', 'bold')
  pdf.text(`Total:`, margin + 30, yOffset)
  pdf.text(`${total}`, margin + 150, yOffset)
  yOffset += 10

  // Footer
  pdf.setFont('helvetica', 'italic')
  pdf.setFontSize(9)
  pdf.text(
    '*Proration adjustments are due to an admin user changing plans during the previous month. Because we charge only once per month, an adjustment is made at the end of the month to account for this.',
    margin,
    yOffset,
    { maxWidth: 180 }
  )

  // Save PDF
  pdf.save(`Invoice ${invoiceNumber || 'Lawcyborg'}.pdf`)
}

export function BillingInvoices(props) {
  const invoices = useAPI('/api/account/invoice')
  return (
    <Card className={props.className}>
      <Table
        loading={invoices.loading}
        badge={{ col: 'status', color: 'red', condition: [{ value: 'paid', color: 'green' }] }}
        data={invoices?.data?.map((x) => {
          return {
            invoice_number: x.number,
            date: x.date,
            status: x.status,
            total: x.total,
            actions: { download: () => generateExactInvoicePDF(x) },
          }
        })}
      />
    </Card>
  )
}

import { moduleIds, practiceAreaIds, practiceAreas } from '@lawcyborg/packages'
import { SettingsContext } from 'app/settings'
import { AppNav, AuthContext, Header } from 'components/lib'
import { useAccess } from 'hooks'
import { CyborgIcon, DocAiIcon, DrFinderIcon, ProvTaxIcon, SearchIcon } from 'icons'
import { useContext } from 'react'
import { HINTS } from 'utils'
import Style from './app.module.scss'

export function AppLayout(props) {
  const auth = useContext(AuthContext)
  const hasAccess = useAccess()
  const settingsContext = useContext(SettingsContext)
  const availableLicensesSet = new Set(auth?.user?.userLicenses?.map((license) => license.module_id))
  const availableLicesnes = Array.from(availableLicensesSet)

  const availablePAs = availableLicesnes.filter((licence) => Object.values(practiceAreaIds).includes(licence)).length
  const shouldHideAiGeneral = settingsContext?.settings?.disable_tool_ai_general || false

  const firstPAId = availableLicesnes.find((licence) => Object.values(practiceAreaIds).includes(licence))
  const firstPA = Object.values(practiceAreas).find((pa) => pa.id === firstPAId)

  const shouldHideDocumentUploader = settingsContext?.settings?.disable_tool_document_uploader || false
  const shouldHideProvtaxCalculator = settingsContext?.settings?.disable_tool_provtax_calculator || false

  const isAIView = props.title === 'AI'
  const isDepView = props.title === 'Depreciation Rate Finder'
  const isSeafarerView = props.title === 'Search Results'
  let bgColorClass = ''
  if (isAIView || isSeafarerView) {
    bgColorClass = Style['app-bg-blue']
  } else if (isDepView) {
    bgColorClass = Style['app-bg-orange']
  }
  const navItems = [
    {
      label: 'AI',
      icon: <CyborgIcon />,
      link:
        availablePAs <= 0
          ? shouldHideAiGeneral
            ? '/account'
            : '/ai/general'
          : availablePAs > 1 || (availablePAs === 1 && !shouldHideAiGeneral)
            ? '/ai/auto'
            : `/ai?practice_area=${firstPA?.slug}`,
      hint: HINTS.cyborg,
      view: hasAccess(moduleIds.ESSENTIAL_PLAN) && (availablePAs > 0 || (availablePAs === 0 && !shouldHideAiGeneral)),
    },
    {
      label: 'Document Questioner',
      icon: <DocAiIcon />,
      link: '/doc-question',
      hint: HINTS.docQuestion,
      view: !shouldHideDocumentUploader && hasAccess(moduleIds.ESSENTIAL_PLAN),
    },
    {
      label: 'Depreciation Rate Finder',
      icon: <DrFinderIcon />,
      link: '/dr-finder',
      hint: HINTS.drFinder,
      view: hasAccess(moduleIds.ESSENTIAL_PLAN) && hasAccess(moduleIds.TAX),
    },
    {
      label: 'Provisional Tax Calculator',
      icon: <ProvTaxIcon />,
      link: '/prov-calc',
      hint: HINTS.provCalc,
      view: !shouldHideProvtaxCalculator && hasAccess(moduleIds.ESSENTIAL_PLAN) && hasAccess(moduleIds.TAX),
    },
    {
      label: 'Law Cyborg Search',
      icon: <SearchIcon fill="#fff" height={15} width={15} />,
      link: '/search',
      hint: HINTS.seafarer,
      view: hasAccess(moduleIds.ELITE_PLAN),
    },
  ]
  return (
    <div className="flex">
      <AppNav
        items={navItems.filter((item) => item.view)}
        accountActionItems={[
          { label: 'Account', icon: 'user', link: '/account' },
          { label: 'Help', icon: 'help-circle', link: '/help' },
        ]}
      />

      <main className={`${Style.app} ${bgColorClass}`}>
        {props.header && <Header title={props.title} options={props.options} />}
        {<props.children {...props.data} />}
      </main>
    </div>
  )
}

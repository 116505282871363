/***
 *
 *   SIGN UP STEP 1
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: verify email address
 *   Step 3: select plan
 *
 **********/

import { useContext, useState } from 'react'
import { AuthContext, Animate, Row, Card, Form, Link } from 'components/lib'
import { Helmet } from 'react-helmet-async'
export function Signup(props) {
  const [referralSource, setReferralSource] = useState('')
  const context = useContext(AuthContext)
  const initialInputs = {
    name: {
      label: 'First Name',
      type: 'text',
      required: true,
      errorMessage: 'Please enter your first name',
    },
    email: {
      label: 'Email',
      type: 'email',
      required: true,
    },
    password: {
      label: 'Password',
      type: 'password',
      required: true,
      complexPassword: true,
    },
    source: {
      label: 'How did you hear about us?',
      type: 'select',
      required: false,
      options: [
        { name: 'Word of mouth', label: 'Word of mouth' },
        { name: 'Law Cyborg Employee', label: 'Law Cyborg Employee' },
        { name: 'LinkedIn', label: 'LinkedIn' },
        { name: 'Facebook', label: 'Facebook' },
        { name: 'Instagram', label: 'Instagram' },
        { name: 'Other', label: 'Other' },
      ],
    },
  }
  const [inputs, setInputs] = useState(initialInputs)

  const handleChange = (e) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [e.input]: {
        ...prevInputs[e.input],
        value: e.value,
      },
      ...(e.input === 'source' &&
        e.value === 'Other' && {
          other: {
            label: 'Other',
            type: 'text',
            required: true,
          },
        }),
    }))
    if (e.input === 'source') {
      setReferralSource(e.value)
    }
    return false
  }

  return (
    <Animate type="pop">
      <Helmet>
        <meta
          name="description"
          content="Join Law Cyborg: AI-powered tax research for Kiwi accountants on the cutting-edge. Sign up for instant access and streamline your workflow."
        />
      </Helmet>
      <Row title="Create Your Account">
        <Card loading={false} restrictWidth center>
          <Form
            inputs={{
              name: {
                label: 'First Name',
                type: 'text',
                value: inputs.name.value,
                required: true,
                errorMessage: 'Please enter your first name',
              },
              email: {
                label: 'Email',
                type: 'email',
                value: inputs.email.value,
                required: true,
              },
              password: {
                label: 'Password',
                type: 'password',
                value: inputs.password.value,
                required: true,
                complexPassword: true,
              },
              confirm_password: {
                type: 'hidden',
                value: null,
              },
              source: {
                label: 'How did you hear about us?',
                type: 'select',
                required: false,
                value: inputs.source.value,
                options: [
                  { name: 'Word of mouth', label: 'Word of mouth' },
                  { name: 'Law Cyborg Employee', label: 'Law Cyborg Employee' },
                  { name: 'LinkedIn', label: 'LinkedIn' },
                  { name: 'Facebook', label: 'Facebook' },
                  { name: 'Instagram', label: 'Instagram' },
                  { name: 'Other', label: 'Other' },
                ],
              },
              ...(referralSource === 'Other' && {
                other: {
                  label: 'Other',
                  type: 'text',
                  value: inputs.other.value,
                  required: true,
                },
              }),
            }}
            url="/api/account"
            method="POST"
            updateOnChange
            onChange={(e) => handleChange(e)}
            buttonText="Create Account"
            callback={context.signin}
          />
          <div className="mt-4">
            Already registered? <Link url="/signin" text="Sign In" />
          </div>
        </Card>
      </Row>
    </Animate>
  )
}
